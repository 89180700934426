import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Homepage from "./pages/index";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import Pricing from "./pages/Pricing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Markets from "./pages/Markets";
import AboutUs from "./pages/AboutUs";
import { LanguageProvider } from "../languagesContext";

const Index = () => {
  console.log('lg')
  return (
    <>
      {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
      <LanguageProvider>
        <BrowserRouter>
          <div id="main-wrapper">
            <Switch>
              <Route path="/" exact component={Homepage} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/markets" component={Markets} />
              <Route path="/signin" component={Signin} />
              <Route path="/signup" component={Signup} />
              <Route path="/about-us" component={AboutUs} />
            </Switch>

            <ToastContainer />
          </div>
        </BrowserRouter>
      </LanguageProvider>
    </>
  );
};

export default Index;
